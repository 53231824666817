<template>
  <v-app>
    <v-container
      class="fill-height"
      style="background-color: #eee"
      fluid
    >
      <v-row
        justify="center"
      >
        <v-col lg="3">
          <center>
            <v-img
              :src="require('@/assets/img/logo.png')"
              max-width="250"
            />
          </center>
          <v-card
            class="rounded-xl"
            elevation="24"
          >
            <v-card-title class="py-6">
              Inicio de Sesion
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="email"
                  label="Email"
                  required
                />
                <v-password-field
                  v-model="password"
                  label="Contraseña"
                  required
                />
                <v-row>
                  <v-col>
                    <span
                      v-for="(error, i) in errors"
                      :key="error-i"
                      class="error--text"
                    >
                      {{ error }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="my-3">
                  <v-col>
                    <center>
                      <v-btn
                        color="primary"
                        @click="login"
                      >
                        Iniciar Sesion
                      </v-btn>
                    </center>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col class="my-3">
                  <router-link to="/forgot-password">
                    Olvide mi contraseña
                  </router-link>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  const Cookie = require('js.cookie')

  export default {
    data () {
      return {
        email: null,
        password: null,
        errors: [],
      }
    },
    methods: {
      async login () {
        const payload = {
          email: this.email,
          password: this.password,
        }
        this.errors.splice(0, this.errors.length)
        try {
          const response = await this.$api.Auth.login(payload)
          const roles = [1, 2]
          if (response.user && response.user.role && roles.includes(response.user.role.id)) {
            Cookie.set('jwt', response.token, { expires: 0.125 }) // Expires in 3 hours
            Cookie.set('username', response.user.name, { expires: 0.125 })
            Cookie.set('userid', response.user.id, { expires: 0.125 })
            this.$store.commit('SET_USER', {
              username: response.user.name,
              userId: response.user.id,
            })
            this.$router.push('/')
          } else {
            this.errors.push('El correo o contraseña ingresados son invalidos')
          }
        } catch {
          this.errors.push('El correo o contraseña ingresados son invalidos')
        }
      },
    },
  }
</script>
